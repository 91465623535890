import { IndexPageTemplate, Head } from '../_landings/index'
import { CredentialsContextProvider } from '../context/credentials-context'
import credentialsConfig, { Config } from '../credentials-config'

export default function ToolsTemplate({
    pageContext,
}: {
    pageContext: { pageButtonsLink: string }
}) {
    const cc: Config = {
        ...credentialsConfig,
        links: {
            ...credentialsConfig.links,
            platform: {
                ...credentialsConfig.links.platform,
                id: {
                    ...credentialsConfig.links.platform.id,
                    reg: pageContext.pageButtonsLink,
                },
            },
        },
    }

    return (
        <CredentialsContextProvider cc={cc}>
            <IndexPageTemplate
                headerSectionTitle={
                    <>
                        All the tools you need <br /> for profitable trading
                    </>
                }
            />
        </CredentialsContextProvider>
    )
}

export { Head }
