import { Container } from '../../components/Container'
import * as pageTheme from './primexbt-start.page.module.scss'
import * as pageCss from './primexbt-start.module.scss'
// import pageCredentials from './credentials-config'
import { Footer } from './Footer'
// import AppStoreIcon from './img/icons/mobiles/AppStore.inline.svg'
// import GooglePlayIcon from './img/icons/mobiles/GooglePlay.inline.svg'
import classNames from 'classnames'
import { ButtonExternalLink } from '../../components/Button/Button'
import React, {useContext} from 'react'
import {CredentialsContext} from "../../context/credentials-context";

const AwardIcon: React.FC<{
    image: string
    text: string
    className?: string
}> = ({ image, text, className }) => {
    return (
        <div className={classNames('tw-flex tw-items-center', className)}>
            <img
                src={require('./img/awards/laurel.svg').default}
                alt={'Left laurel icon'}
                width={32}
                height={68}
            />
            <img
                src={image}
                alt="Award icon"
                width={46}
                height={46}
                className={'tw-w-[46px] tw-ml-[14px]'}
            />
            <strong className="tw-mx-[14px] tw-max-w-[102px] tw-text-center tw-font-[400]">
                {text}
            </strong>
            <img
                src={require('./img/awards/laurel.svg').default}
                alt={'Right laurel icon'}
                width={32}
                height={68}
                className="tw-scale-x-[-1]"
            />
        </div>
    )
}

const HeaderSection = ({
    title = (
        <>
            PrimeXBT <br /> trading platform
        </>
    ),
}: {
    title?: React.ReactNode
}) => {
    const pageCredentials = useContext(CredentialsContext)
    return (
        <section className={pageCss.headerSection}>
            <Container>
                <div className="tw-z-1 tw-relative">
                    <h1>
                        <span className="tw-text-[24px] tablet:tw-text-[54px]">
                            {title}
                        </span>
                    </h1>
                    <p>
                        Trade the world’s markets with our award-winning
                        platform
                    </p>
                    {/* prettier-ignore */}
                    <img
                        srcSet={`${require('./img/multiple-displays-platform-preview.png').default} 1x,
                             ${require('./img/multiple-displays-platform-preview@2x.png').default} 2x`}
                        src={require('./img/multiple-displays-platform-preview.png').default}
                        alt='PrimeXBT platform devices preview'
                        loading={'eager'}
                        width={1274}
                        height={1328}
                        className={pageCss.headerSectionSideImage}
                    />
                    <ButtonExternalLink
                        href={pageCredentials.links.platform.id.reg}
                    >
                        Open free account
                    </ButtonExternalLink>
                    <div className="tw-mt-[32px] tablet:tw-mt-[70px]">
                        <AwardIcon
                            image={require('./img/awards/1.svg').default}
                            text="Best Platform for Trading"
                            className="tw-mb-[20px] desktop:tw-mb-0 desktop:tw-inline-flex desktop:tw-mr-[32px] tw-justify-center tablet:tw-justify-start"
                        />
                        <AwardIcon
                            image={require('./img/awards/2.png').default}
                            text="Best Trading Application"
                            className="desktop:tw-inline-flex tw-justify-center tablet:tw-justify-start"
                        />
                    </div>
                </div>
            </Container>
        </section>
    )
}

const SecondSection = () => {
    const pageCredentials = useContext(CredentialsContext)
    return (
        <section
            className={classNames(
                'tw-py-[50px] tw-overflow-hidden',
                pageCss.secondSection
            )}
        >
            <Container>
                <h2 className="tw-mb-[50px] tw-text-center">
                    Trading for everyone
                </h2>
                <div className="tw-flex tw-justify-center tw-mb-[24px] desktop:tw-mb-[31px]">
                    {/* prettier-ignore */}
                    <img
                        srcSet={`${require('./img/devices-platform-preview.png').default} 1x,
                             ${require('./img/devices-platform-preview@2x.png').default} 2x`}
                        src={require('./img/devices-platform-preview.png').default}
                        loading='eager'
                        width={899}
                        height={469}
                        className='tw-max-w-[720px] tw-block tablet:tw-max-w-[640px] desktop:tw-max-w-[895px]'
                        alt='PrimeXBT platform devices preview'
                    />
                </div>
                <div className={pageCss.secondSectionRow}>
                    <strong>
                        <span>150 000+</span>
                        <span>Daily trades</span>
                    </strong>
                    <div className={pageCss.secondSectionDelimiter}></div>
                    <strong>
                        <span>185</span>
                        <span>Countries served</span>
                    </strong>
                    <div className={pageCss.secondSectionDelimiter}></div>
                    <strong>
                        <span>
                            99.9
                            <span className="tw-text-[24px] tablet:tw-text-[32px] tw-leading-none">
                                %
                            </span>
                        </span>
                        <span>Uptime</span>
                    </strong>
                </div>
                <div className="tw-text-center">
                    <ButtonExternalLink
                        className="tw-mx-auto"
                        href={pageCredentials.links.platform.id.reg}
                    >
                        Open free account
                    </ButtonExternalLink>
                </div>
            </Container>
        </section>
    )
}

const BenefitsSection = () => {
    return (
        <section className="tw-bg-prime-light-blue tw-py-[50px]">
            <Container>
                <h2 className="tw-text-center tw-mb-[32px] tablet:tw-mb-[50px]">
                    The benefits of the platform
                </h2>
                <div className="tw-flex tw-flex-col tablet:tw-flex-row tablet:tw-justify-center">
                    <article className={pageCss.benefitsCard}>
                        <img
                            src={
                                require('./img/benefits-icons/benefits-1.svg')
                                    .default
                            }
                            alt={'Charts icon'}
                            width={72}
                            height={72}
                            className="tw-inline-block tw-mb-[16px]"
                        />
                        <h3>Professional Charts</h3>
                        <p>
                            Place trades, manage orders and work directly from
                            our integrated charts. Use our comprehensive suite
                            of drawing tools and over 50 technical indicators to
                            analyze price trends.
                        </p>
                    </article>
                    <article className={pageCss.benefitsCard}>
                        <img
                            src={
                                require('./img/benefits-icons/benefits-2.svg')
                                    .default
                            }
                            alt={'Platform layout icon'}
                            width={72}
                            height={72}
                            className="tw-inline-block tw-mb-[16px]"
                        />
                        <h3>Customized Platform Layout</h3>
                        <p>
                            A fully customizable workspace, which includes
                            intuitive visualization of market data, and offers
                            an extensive suite of trading tools and that is not
                            all that you can't get! Setup your perfect trading
                            environment now.
                        </p>
                    </article>
                    <article className={pageCss.benefitsCard}>
                        <img
                            src={
                                require('./img/benefits-icons/benefits-3.svg')
                                    .default
                            }
                            alt={'Order types icon'}
                            width={72}
                            height={72}
                            className="tw-inline-block tw-mb-[16px]"
                        />
                        <h3>Multiple Order Types</h3>
                        <p>
                            PrimeXBT offers multiple order types available in
                            the Order Form option to give you the necessary
                            tools to execute your trading strategy and provides
                            increased flexibility on how you enter trades.
                        </p>
                    </article>
                </div>
            </Container>
        </section>
    )
}

const OpportunitiesSection = () => {
    const pageCredentials = useContext(CredentialsContext)
    return (
        <section className="tw-py-[50px]">
            <Container>
                <h2 className="tw-text-center tw-mb-[32px] tablet:tw-mb-[50px]">
                    One platform — plenty of opportunities
                </h2>
                <p className="tw-text-center tw-mb-[32px] tablet:tw-mb-[50px] tw-text-[16px] tablet:tw-text-[19px]">
                    Trade, hold and earn with one of the world’s fastest and
                    most reliable platforms.
                </p>
                <div className="tw-flex tw-flex-col-reverse tw-items-center desktop:tw-flex-row">
                    <div>
                        <ul className="tw-max-w-[539px] tw-mb-[32px] desktop:tw-mr-[57px] tablet:tw-mb-[50px]">
                            <li className={pageCss.listItem}>
                                <h3 className="tw-font-[600] tw-mb-[10px]">
                                    Competition module
                                </h3>
                                <p className={'tw-leading-[24px]'}>
                                    You can always improve your trading skills
                                    on the competition module. When you're
                                    ready, switch to your real account.
                                </p>
                            </li>
                            <li className={pageCss.listItem}>
                                <h3 className="tw-font-[600] tw-mb-[10px]">
                                    Online support
                                </h3>
                                <p className={'tw-leading-[24px]'}>
                                    You can send a message anytime via chat and
                                    get feedback right away!
                                </p>
                            </li>
                            <li className={pageCss.listItem}>
                                <h3 className="tw-font-[600] tw-mb-[10px]">
                                    Free training
                                </h3>
                                <p className={'tw-leading-[24px]'}>
                                    By studying the extensive knowledge base,
                                    you will be able to improve your trading
                                    skills for better results.
                                </p>
                            </li>
                        </ul>
                        <div className="tw-text-center desktop:tw-text-left">
                            <ButtonExternalLink
                                href={pageCredentials.links.platform.id.reg}
                            >
                                Preview platform
                            </ButtonExternalLink>
                        </div>
                    </div>
                    <div>
                        {/* prettier-ignore */}
                        <img
                            srcSet={`${require('./img/laptop-platform-preview.png').default} 1x,
                                    ${require('./img/laptop-platform-preview@2x.png').default} 2x`}
                            src={require('./img/laptop-platform-preview.png').default}
                            alt={'MacBook Pro 13 platform preview'}
                            loading={'lazy'}
                            width={668}
                            height={387}
                            className='tw-mb-[32px] tw-w-[100%] tablet:tw-max-w-[668px] desktop:tw-mb-0'
                        />
                    </div>
                </div>
            </Container>
        </section>
    )
}

const MobilesSection = () => {
    return (
        <section
            className={classNames(
                'tw-pb-[31px] tablet:tw-py-[50px] desktop:tw-pb-[0]',
                pageCss.mobilesSection
            )}
        >
            <Container>
                <div className="tw-flex tw-flex-col-reverse tablet:tw-flex-row tablet:tw-justify-center tw-items-start">
                    {/* prettier-ignore */}
                    <img
                        srcSet={`${require('./img/mobile-platform-preview.png').default} 1x,
                                 ${require('./img/mobile-platform-preview@2x.png').default} 2x`}
                        src={require('./img/mobile-platform-preview.png').default}
                        alt={'Mobile platform preview'}
                        width={449}
                        height={531}
                        loading={'lazy'}
                    />
                    <div className="tablet:tw-max-w-[348px] desktop:tw-max-w-[592px] tw-mb-[31px] tablet:tw-mb-0 tablet:tw-ml-[24px] desktop:tw-ml-[112px]">
                        <h2 className='tw-text-center "tw-text-center tablet:tw-text-left tw-mb-[32px] tablet:tw-mb-[50px]'>
                            Trade <br /> from anywhere!
                        </h2>
                        <p className="tw-text-center tablet:tw-text-left tw-mb-[32px] tablet:tw-mb-[50px] tw-text-[16px] tablet:tw-text-[19px]">
                            You can trade anytime and anywhere with the PrimeXBT
                            app for iOS and Android. Stay in the know: instant
                            information on trade closings, promotions and
                            tournaments.
                        </p>
                        {/*<div*/}
                        {/*    className={*/}
                        {/*        'tw-text-center tablet:tw-text-left tw-whitespace-nowrap'*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    <a*/}
                        {/*        href={pageCredentials.links.mobiles.appStore}*/}
                        {/*        target={'_blank'}*/}
                        {/*    >*/}
                        {/*        <AppStoreIcon*/}
                        {/*            className={'tw-inline tw-mr-[24px]'}*/}
                        {/*        />*/}
                        {/*    </a>*/}
                        {/*    <a*/}
                        {/*        href={pageCredentials.links.mobiles.googlePlay}*/}
                        {/*        target={'_blank'}*/}
                        {/*    >*/}
                        {/*        <GooglePlayIcon className={'tw-inline'} />*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </Container>
        </section>
    )
}

export const IndexPageTemplate = ({
    headerSectionTitle,
}: {
    headerSectionTitle?: React.ReactNode
}) => {
    return (
        <div className={pageTheme.primexbtStartPage}>
            <header className="tw-absolute tw-z-[1] tw-top-0 tw-left-0 tw-right-0 tw-pt-[31px] tablet:tw-pt-[45px] ">
                <Container>
                    {/*<a href={pageCredentials.links.site.main}>*/}
                    <img
                        src={
                            require('../../images/icons/logo/logo-white.svg')
                                .default
                        }
                        alt={'PrimeXBT logo'}
                        width={156}
                        height={18}
                        className="tw-w-[116px] tw-h-[14px] tablet:tw-w-[155px] tablet:tw-h-[18px]"
                    />
                    {/*</a>*/}
                </Container>
            </header>
            <main>
                <HeaderSection
                    {...(headerSectionTitle
                        ? { title: headerSectionTitle }
                        : {})}
                />
                <SecondSection />
                <BenefitsSection />
                <div className={pageCss.footerGradient}>
                    <OpportunitiesSection />
                    <MobilesSection />
                </div>
            </main>
            <Footer />
        </div>
    )
}

const Head = () => {
    return (
        <>
            <title>PrimeXBT trading platform</title>
            <meta
                name="description"
                content="Trade the world’s markets with our award-winning platform"
            />
        </>
    )
}

export { Head }
